import React, { useEffect, useRef, useState } from 'react';
import CreatePage from './CreatePage';
import './App.css';
import axios from 'axios';


const App = () => {
  const [user, setUser] = useState(0);
  const [token, setToken] = useState("");
  const [showMsg, setMsg] = useState(undefined);
  const loginRef = useRef();
  const pwRef = useRef();

  useEffect(() => { document.title = ""; loginRef.current.focus()}, []);

  const login = e => {
    if((e.key && e.key === "Enter") || e.target.value === "Submit")
    {
      if(loginRef.current.value.length === 0)
      {
          setMsg("Please enter your login");
          return;
      }

      if(pwRef.current.value.length === 0)
      {
        setMsg("Please enter your password");
        return;
      }

      axios
        .post("/api/login", {user: loginRef.current.value, password: pwRef.current.value})
        .then(res => {
          setUser(res.data.user_id);
          setToken(res.data.token);
        })
        .catch(err => console.log(err));
    }
  }

  return  <div className="App">
            { user ?
              <CreatePage user={user} token={token} /> :
              <div className="splash">
                <div className="wrapper">
                  <div className="login">
                    <input type="text" className="login-item" placeholder="login" ref={loginRef} onChange={() => setMsg(undefined)} />
                    <input type="password" className="login-item" placeholder="password" ref={pwRef} onKeyUp={e => login(e) } onChange={() => setMsg(undefined)} />
                  </div>
                  <input type="button" className="login-item" onClick={login} value="Submit" />
                </div>
                {showMsg && <div className="warning">{showMsg}</div>}
              </div>
            }
          </div>
}

export default App;