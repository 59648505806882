import React, { useState } from 'react';
import OptionPanel from './OptionalPanel';
import axios from 'axios';

import KeyParts from './keyparts.json';

const CreatePage = (props) => {
    const [keys, setKeys] = useState(KeyParts["Key Info"]);
    const [dbs, setDBs] = useState(KeyParts["DBs"]);
    const [options, setOptions] = useState(KeyParts["Options"]);
    const [base, setBase] = useState(KeyParts["Base Tabs"]);
    const [optional, setOptional] = useState(KeyParts["Optional Tabs"]);

    const createKey = () => {
        let data = {user_id:props.user, token:props.token};
        Object.keys(keys).forEach(k => {
            data[k.toLowerCase().replaceAll(" ", "_")] = keys[k].value;
        });
        Object.keys(dbs).forEach(k => {
            data[k.toLowerCase().replaceAll(" ", "")] = dbs[k].value;
        });
        Object.keys(options).forEach(k => {
            data[k.toLowerCase().replaceAll(" ", "_")] = options[k].value;
        });
        Object.keys(base).forEach(k => {
            data[k.toLowerCase().replaceAll(" ", "_") + "_tab"] = base[k].value;
        });
        Object.keys(optional).forEach(k => {
            data[k.toLowerCase().replaceAll(" ", "_") + "_tab"] = optional[k].value;
        });
        
        axios
            .post("/api/newkey", data, {responseType:'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/octet-stream'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res.headers["content-disposition"].split("filename=")[1]);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
            })
    }

    const download = release => {
        axios
            .post("/api/download/" + release, {user_id:props.user, token:props.token}, {responseType:'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/octet-stream'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res.headers["content-disposition"].split("filename=")[1]);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
            })
    }

    return  <div className="create-page">
                <div className="button-panel">
                    <div className="flatfileloader-button" onClick={() => download("v6")}>Download Version 6</div>
                    <div className="flatfileloader-button" onClick={() => download("v7")}>Download Version 7</div>
                    <div className="flatfileloader-button" onClick={() => download("v8")}>Download Version 8</div>
                </div>
                <div className="option-panel">
                    <div className="option-row">
                        <OptionPanel items={keys} update={setKeys} title="Key Info"/>
                        <OptionPanel items={dbs} update={setDBs} title="DBs"/>
                        <OptionPanel items={options} update={setOptions}  title="Options"/>
                    </div>
                    <div className="option-row">
                        <OptionPanel items={base} update={setBase} title="Base Tabs"/>
                        <OptionPanel items={optional} update={setOptional}  title="Optional Tabs"/>
                    </div>
                </div>
                <div className="bottom-panel">
                    <div className="flatfileloader-button" onClick={createKey}>Create Key</div>
                </div>
            </div>
}

export default CreatePage;
