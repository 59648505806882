import React from 'react';

const OptionalPanel = (props) => {

    const update = e => {
        let items = {...props.items};
        let k = e.target.dataset.kv;
        items[k].value = e.target.value === "on" ? !items[k].value : e.target.value;
        props.update(items);
    }

    const getItems = () => {
        let ret = [];
        Object.keys(props.items).forEach((k, i) => {
            let v = props.items[k];
            ret.push(   <div className="op-item" key={i}>
                            <div className="op-item-title">{k}</div>
                            <div className="op-item-value">
                                {v.type === "check" 
                                ?
                                        <input type="checkbox" checked={v.value && v.value !== "false" ? true : false} onChange={update} disabled={v.disabled ? true : false} data-kv={k}/>
                                :
                                    <input type="text" value={v.value} onChange={update} data-kv={k}/>
                                }
                            </div>
                        </div>)
        })

        return ret;
    }

    return  <div className="optional-panel">
                <div className="op-title">
                    {props.title}
                </div>
                <div className="op-items">
                    {getItems()}
                </div>
            </div>
}

export default OptionalPanel;
